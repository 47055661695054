import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { routing } from './app.routing';
import { AppConfig } from './app.config';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ErrorComponent } from './pages/error/error.component';
import { HttpModule } from '@angular/http';
import { StorageServiceModule, SESSION_STORAGE, StorageService } from 'angular-webstorage-service';
import { GridModule } from '@progress/kendo-angular-grid';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { NgxSpinnerModule } from "ngx-spinner";  
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyHttpInterceptor } from './httpinte';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UserSessionContex } from './UserSessionContex';


@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
  ],
  imports: [
      HttpModule,
      FormsModule,
      StorageServiceModule,
      BrowserModule,
      NgxSpinnerModule,
      CommonModule,
      GridModule,
      HttpClientModule,
      BrowserAnimationsModule,
      AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDe_oVpi9eRSN99G4o6TwVjJbFBNr58NxE'
    }),
    routing,
    ToolBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })   
  ],
    providers:
        [
            AppConfig,
            UserSessionContex,
        {
        provide: HTTP_INTERCEPTORS,
        useClass: MyHttpInterceptor,
            multi: true,
            }
        ],
  bootstrap: [AppComponent]
})
export class AppModule { }
